import React from 'react';
import axios from 'axios';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
//const { Bitbucket } = require('bitbucket')











function App() {

  return (
    <div className="App">
      <header className="App-header">
        <ROSConnector />
      </header>
    </div>
  );
}

class ROSConnector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password'),
      apiFile: localStorage.getItem('apiFile'),
      apiSpec: ""//localStorage.getItem('apiSpec') !== null ? localStorage.getItem('apiSpec') : ""
    }

  }
  async getAPISpec() {
    localStorage.setItem('username', this.state.username);
    localStorage.setItem('password', this.state.password);
    localStorage.setItem('apiFile', this.state.apiFile);
    const response = await axios.get(
      this.state.apiFile,
      {
        auth: {
          username: this.state.username,
          password: this.state.password
        },
      },
      { headers: { 'Content-Type': 'application/x-yaml' } }
    )
    this.setState({ apiSpec: response.data })
    localStorage.setItem('apiSpec', response.data);
    console.log("apiSpec", response.data)
  }


  setUsername(value) {
    console.log("username", value)
    this.setState({ username: value })
  }

  setPassword(value) {
    this.setState({ password: value })
  }

  setApiPort(value) {
    console.log("apiFile", value)
    this.setState({ apiFile: value })
  }
  render() {
    return <div>
      <h1>API viewer</h1>
      <label>Username:  <input onChange={(event) => { this.setUsername(event.target.value) }} name="host" id="username" value={this.state.username} /> </label><br />
      <label>Password:  <input type="password" onChange={(event) => { this.setPassword(event.target.value) }} name="port" id="password" value={this.state.password} /> </label><br />
      <label>API Specs:  <input onChange={(event) => { this.setApiPort(event.target.value) }} name="port" id="apiFile" value={this.state.apiFile} /> </label><br />

      <button onClick={(event) => { this.getAPISpec() }} >Get API specs</button>

      <SwaggerUI spec={this.state.apiSpec} />

    </div>;
  }
}

export default App;
